





























import ViewModel from '@/models/ViewModel'

export default class FooterNav extends ViewModel {}
